<div [appLoadingSpinner]="isLoading()">
    <table mat-table [dataSource]="bookingTableData()" matSort matSortActive="startDate" matSortDirection="desc"
        class="mat-elevation-z8">

        <ng-container matColumnDef="startDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Ankunft </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate.toLocaleDateString()}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Abreise </th>
            <td mat-cell *matCellDef="let element"> {{element.endDate.toLocaleDateString()}} </td>
        </ng-container>

        <ng-container matColumnDef="contactLastName">
            <th mat-header-cell *matHeaderCellDef> Nachname </th>
            <td mat-cell *matCellDef="let element"> {{element.contactPerson.lastName}} </td>
        </ng-container>

        <ng-container matColumnDef="numberOfGuests">
            <th mat-header-cell *matHeaderCellDef> Anzahl Personen </th>
            <td mat-cell *matCellDef="let element"> {{getTotalNumberOfGuests(element)}} </td>
        </ng-container>

        <ng-container matColumnDef="occupiedRooms">
            <th mat-header-cell *matHeaderCellDef> Belegte Zimmer </th>
            <td mat-cell *matCellDef="let element"> {{getCommaSeparatedRoomNumbers(element)}} </td>
        </ng-container>

        <ng-container matColumnDef="bookedAt">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Gebucht am </th>
            <td mat-cell *matCellDef="let element"> {{element.bookedAt.toLocaleDateString('de-de')}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> <button mat-button color="primary"
                    (click)="$event.stopPropagation(); createInvoice(element.id)"><mat-icon fontIcon="receipt_long"></mat-icon></button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row.id)"
            class="hover:cursor-pointer"></tr>

    </table>
    <!--TODO: could add icons here-->
    <button mat-fab color="primary" class="m-3" (click)="openAddDialog()"><mat-icon fontIcon="add"></mat-icon></button>
</div>