import { Directive, ElementRef, ViewContainerRef } from '@angular/core';
import { Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: '[appLoadingSpinner]'
})
export class LoadingSpinnerDirective implements OnChanges {
  constructor(private el: ElementRef, private renderer: Renderer2, private vcr: ViewContainerRef) {
    this.overlay = this.renderer.createElement('div');
    const componentRef = this.vcr.createComponent(MatProgressSpinner);
    componentRef.instance.mode = 'indeterminate';
    const spinner = componentRef.location.nativeElement;
    this.renderer.setStyle(this.overlay, 'position', 'absolute');
    this.renderer.setStyle(this.overlay, 'top', '0');
    this.renderer.setStyle(this.overlay, 'left', '0');
    this.renderer.setStyle(this.overlay, 'width', '100%');
    this.renderer.setStyle(this.overlay, 'height', '100%');
    this.renderer.setStyle(this.overlay, 'background', 'rgba(255, 255, 255, 0.6)');
    this.renderer.setStyle(this.overlay, 'display', 'flex');
    this.renderer.setStyle(this.overlay, 'align-items', 'center');
    this.renderer.setStyle(this.overlay, 'justify-content', 'center');
    this.renderer.setStyle(this.overlay, 'z-index', '1000');
    this.renderer.appendChild(this.overlay, spinner);
   }

  @Input() appLoadingSpinner: boolean = false;
  private overlay: HTMLDivElement | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appLoadingSpinner']) {
      if (this.appLoadingSpinner) {
        this.showSpinner();
      } else {
        this.hideSpinner();
      }
    }
  }

  private showSpinner(): void {
    this.renderer.appendChild(this.el.nativeElement, this.overlay);
  }

  private hideSpinner(): void {
    if (this.overlay) {
      this.renderer.removeChild(this.el.nativeElement, this.overlay);
    }
  }
}
