import { Component, Inject, Signal, signal } from '@angular/core';
import { BookingsService, Address } from '../bookings.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-bill-dialog',
  templateUrl: './create-bill-dialog.component.html',
  styleUrl: './create-bill-dialog.component.css',
  standalone: false
})
export class CreateBillDialogComponent {
  constructor(private bookingService: BookingsService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateBillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedBookingId: string,
  ) {
    this.isLoading = this.bookingService.getIsLoading();
  }

  public isLoading: Signal<boolean> = signal<boolean>(false);

  public billFormGroup = this.formBuilder.group({
    numberOfBreakfasts: new FormControl<number | null>(null, {validators: [Validators.min(0)]}),
    numberOfChildrenBreakfasts: new FormControl<number | null>(null, {validators: [Validators.min(0)]}),
    drinksPrice: new FormControl<number | null>(null, {validators: [Validators.min(0)]}),

    billingName: new FormControl<string>('', {validators: [Validators.required, Validators.minLength(1)]}),
    billingCompany: new FormControl<string | null>(null, {validators: [Validators.minLength(1)]}),
    billingStreet: new FormControl<string>('', {validators: [Validators.required, Validators.minLength(1)]}),
    billingPostalCode: new FormControl<string>('', {validators: [Validators.required, Validators.minLength(1)]}),
    billingCity: new FormControl<string>('', {validators: [Validators.required, Validators.minLength(1)]}),
  });

  public async onSaveClick(): Promise<void> {
    const numberOfBreakfasts = this.billFormGroup.get('numberOfBreakfasts')?.value ?? null;
    const numberOfChildrenBreakfasts = this.billFormGroup.get('numberOfChildrenBreakfasts')?.value ?? null;
    const drinksPrice = this.billFormGroup.get('drinksPrice')?.value ?? null;
    const billingCompany = this.billFormGroup.get('billingCompany')?.value ?? null;
    // TODO: actually handle instead of just using empty strings
    const address: Address = {
      name: this.billFormGroup.get('billingName')?.value ?? '',
      street: this.billFormGroup.get('billingStreet')?.value ?? '',
      postalCode: this.billFormGroup.get('billingPostalCode')?.value ?? '',
      city: this.billFormGroup.get('billingCity')?.value ?? '',
      company: billingCompany,
    } 
    await this.bookingService.createPdfInvoice(this.selectedBookingId, address, drinksPrice, numberOfBreakfasts, numberOfChildrenBreakfasts);
    this.dialogRef.close();
  }

}
