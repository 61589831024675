import { DateAdapter } from "@angular/material/core";

export class DateOnlyAdapter extends DateAdapter<DateOnly> {
    override getYear(date: DateOnly): number {
        return date.year;
    }
    override getMonth(date: DateOnly): number {
        return date.month;
    }
    override getDate(date: DateOnly): number {
        return date.day;
    }
    override getDayOfWeek(date: DateOnly): number {
        return new Date(date.year, date.month, date.day).getDay();
    }
    override getMonthNames(style: "long" | "short" | "narrow"): string[] {
        switch (style) {
            case "long":
                return ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
            case "short":
                return ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
            case "narrow":
                return ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
        };
    }
    override getDateNames(): string[] {
        return Array.from({ length: 31 }, (_, i) => (i + 1).toString());
    }

    override getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
        switch (style) {
            case "long":
                return ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
            case "short":
                return ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
            case "narrow":
                return ["S", "M", "D", "M", "D", "F", "S"];
        }
    }
    override getYearName(date: DateOnly): string {
        return date.year.toString();
    }
    override getFirstDayOfWeek(): number {
        return 1;
    }
    override getNumDaysInMonth(date: DateOnly): number {
        return new Date(date.year, date.month + 1, 0).getDate();
    }
    override clone(date: DateOnly): DateOnly {
        return new DateOnly(date.year, date.month, date.day);
    }
    override createDate(year: number, month: number, date: number): DateOnly {
        return new DateOnly(year, month, date);
    }
    override today(): DateOnly {
        const date = new Date();
        return new DateOnly(date.getFullYear(), date.getMonth(), date.getDate());
    }
    override parse(value: any, parseFormat: any): DateOnly | null {
        if (!value) return null;
        var date = new Date(value);
        return new DateOnly(date.getFullYear(), date.getMonth(), date.getDate());
    }
    override format(date: DateOnly, displayFormat: any): string {
        return `${date.day}.${date.month + 1}.${date.year}`;
    }
    override addCalendarYears(date: DateOnly, years: number): DateOnly {
        var actualDate = new Date(date.year + years, date.month, date.day);
        return new DateOnly(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate());
    }
    override addCalendarMonths(date: DateOnly, months: number): DateOnly {
        var actualDate = new Date(date.year, date.month + months, date.day);
        return new DateOnly(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate());
    }
    override addCalendarDays(date: DateOnly, days: number): DateOnly {
        var actualDate = new Date(date.year, date.month, date.day + days);
        return new DateOnly(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate());
    }
    override toIso8601(date: DateOnly): string {
        return `${date.year}-${date.month+1}-${date.day}`;
    }
    override isDateInstance(obj: any): boolean {
        return obj instanceof DateOnly;
    }
    override isValid(date: DateOnly): boolean {
        const nonNegative = date.year>0 && date.month>=0 && date.day>0;
        const validMonth = date.month <= 12;
        const validDay = date.day <= this.getNumDaysInMonth(date);
        return nonNegative && validMonth && validDay;
    }
    override invalid(): DateOnly {
        return new DateOnly(-1, -1, -1);
    }
}

export class DateOnly {
    constructor(public year: number, public month: number, public day: number) {}
    public toISOString(): string{
        return `${this.year}-${this.month+1}-${this.day}`;
    }
    public toLocaleDateString(): string{
        return `${this.year}.${(this.month+1).toString().padStart(2, '0')}.${this.day.toString().padStart(2, '0')}`;
    }
    static parse(stringToParse: string): DateOnly {
        var split = stringToParse.split('-').map(x => parseInt(x));
        return new DateOnly(split[0], split[1]-1, split[2]);
    }
    public toString(): string {
        return `${this.year}-${(this.month + 1).toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}`;
    }
    public toJSON(): string {
        return this.toString();
    }
}
