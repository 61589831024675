<div [appLoadingSpinner]="isLoading()" [formGroup]="billFormGroup">
    <h2 mat-dialog-title>Rechnung erstellen</h2>
    <mat-dialog-content>
        <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
            <mat-form-field>
                <mat-label>Preis der Getränke insgesamt</mat-label>
                <input type="number" matInput formControlName="drinksPrice" placeholder="Preis Getränke"
                    [defaultValue]="0">
                <span matTextPrefix>€&nbsp;</span>
            </mat-form-field>
            <mat-form-field class="col-start-1">
                <mat-label>Anzahl der Frühstücke</mat-label>
                <input type="number" matInput formControlName="numberOfBreakfasts" placeholder="Anzahl Frühstücke"
                    [defaultValue]="0">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Anzahl der Frühstücke (Kind)</mat-label>
                <input type="number" matInput formControlName="numberOfChildrenBreakfasts" placeholder="Anzahl Frühstücke (Kind)"
                    [defaultValue]="0">
            </mat-form-field>
        </div>
            <h3> Rechnungsadresse</h3>
        <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
            <mat-form-field >
                <mat-label>Vor- und Nachname</mat-label>
                <input type="text" matInput formControlName="billingName" placeholder="Name" required>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Firma</mat-label>
                <input type="text" matInput formControlName="billingCompany" placeholder="Firma">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Straße und Nummer</mat-label>
                <input type="text" matInput formControlName="billingStreet" placeholder="Straße" required>
            </mat-form-field>
            <mat-form-field class="col-start-1">
                <mat-label>Postleitzahl</mat-label>
                <input type="text" matInput formControlName="billingPostalCode" placeholder="PLZ" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Stadt</mat-label>
                <input type="text" matInput formControlName="billingCity" placeholder="Stadt" required>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>

        <button mat-raised-button [disabled]="isLoading()" [mat-dialog-close]="false">Schließen</button>
        <button mat-raised-button [disabled]="isLoading() || billFormGroup.invalid" color="primary" (click)="onSaveClick()">Rechnung
            erstellen</button>
    </mat-dialog-actions>

</div>