import { Component, computed, input, OnInit, signal, Signal } from '@angular/core';
import { Booking, BookingsService, RoomBooking } from '../bookings.service';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
    selector: 'app-bookings-overview-calendar',
    templateUrl: './bookings-overview-calendar.component.html',
    styleUrls: ['./bookings-overview-calendar.component.css'],
    standalone: false
})
export class BookingsOverviewCalendarComponent implements OnInit {

  constructor(private bookingsService: BookingsService){}
  private colors: { [key: string]: string } = {
    "1": "#1f77b4", // blue
    "2": "#ff7f0e", // orange
    "3": "#2ca02c", // green,
    "4": "#ffdd57", // yellow
    "5": "#9467bd", // purple
    "6": "#8c564b", // brown
    "7": "#e377c2", // pink
    "8": "#7f7f7f", // gray
    "ausgebucht": "#d62728", // red
  }
  private roomBookings: Signal<RoomBooking[]> = signal([]);
  public events: Signal<EventInput[]> = computed(() => this.mapBookingToEvent(this.roomBookings()));
  public isLoading: Signal<boolean> = signal(false);

  public calendarOptions: CalendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin],
      locale: 'de',
      firstDay: 1,
      buttonText: { today: 'heute' },
      eventContent: (arg) => {
        return { html: `<div>${arg.event.title}</div>` };
      },
      eventOrder: 'title',
      height: 'auto',
      fixedWeekCount: false,
    };

  ngOnInit(): void {
    this.roomBookings = this.bookingsService.getRoomBookings();
    this.isLoading = this.bookingsService.getIsLoading();
    this.bookingsService.fetchRoomBookings();
  }

  private mapBookingToEvent(bookings: RoomBooking[]): EventInput[] {
    const allEvents: EventInput[] = [];
    const eventMap: { [key: string]: EventInput[] } = {};

    bookings.forEach(booking => {
      const room = booking.occupiedRoom;
      const event: EventInput = {
        title: room.roomNumber,
        start: new Date(booking.fromDate.year, booking.fromDate.month, booking.fromDate.day),
        end: new Date(booking.toDate.year, booking.toDate.month, booking.toDate.day),
        allDay: true,
        color: this.colors[room.roomNumber],
      };

      const key = `${booking.fromDate}-${booking.toDate}`;
      if (!eventMap[key]) {
        eventMap[key] = [];
      }
      eventMap[key].push(event);
    });

    for (const key in eventMap) {
      if (eventMap[key].length >= 8) {
        allEvents.push({
          title: 'ausgebucht',
          start: eventMap[key][0].start,
          end: eventMap[key][0].end,
          allDay: true,
          color: this.colors["ausgebucht"], // black for "ausgebucht"
        });
      } else {
        allEvents.push(...eventMap[key]);
      }
    }

    return allEvents;
  }
}
