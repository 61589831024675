<div [appLoadingSpinner]="isLoading()">
    <h2 mat-dialog-title>Neue Buchung hinzufügen</h2>
    <mat-dialog-content>
        <mat-stepper orientation="horizontal" [linear]="false" #stepper (selectedIndexChange)="onSelectionChange()">
            <mat-step [stepControl]="contactFormGroup">
                <form [formGroup]="contactFormGroup">
                    <ng-template matStepLabel>Kontaktdaten</ng-template>
                    <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
                        <mat-form-field>
                            <mat-label>Vorname</mat-label>
                            <input type="text" matInput placeholder="Max" formControlName="firstName" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Nachname</mat-label>
                            <input type="text" matInput placeholder="Mustermann" formControlName="lastName" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Telefonnummer</mat-label>
                            <input type="tel" matInput placeholder="012345678" formControlName="phone">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input type="email" matInput placeholder="info@example.com" formControlName="email">
                        </mat-form-field>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="stayFormGroup">
                <form [formGroup]="stayFormGroup">
                    <ng-template matStepLabel>Übernachtungsdaten</ng-template>
                    <div class="grid md:grid-cols-3 grid-cols-1 gap-5">
                        <mat-form-field class="md:col-span-2">
                            <mat-label>Ankunft und Abreise</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="startDate" placeholder="Ankunft">
                                <input matEndDate formControlName="endDate" placeholder="Abreise">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                        <mat-form-field class="col-start-1">
                            <mat-label>Anzahl Erwachsene</mat-label>
                            <input type="number" matInput formControlName="numberOfAdults" placeholder="0" [defaultValue]="0" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Anzahl Jugendliche</mat-label>
                            <input type="number" matInput formControlName="numberOfYouths" placeholder="0" [defaultValue]="0" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Anzahl Kinder (bis 6 Jahre)</mat-label>
                            <input type="number" matInput formControlName="numberOfChildren" placeholder="0" [defaultValue]="0" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Belegte Zimmer</mat-label>
                            <mat-select formControlName="occupiedRooms" multiple>
                                <mat-option *ngFor="let room of rooms()" [value]="room">
                                    {{room.roomNumber}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <mat-checkbox formControlName="force">Über und Doppeltbelegung ignorieren</mat-checkbox>
                            <!--TODO only show this on focus loss of room selector-->
                            <mat-error *ngIf="stayFormGroup.errors?.['capacityExceeded'] && stayFormGroup.controls['occupiedRooms'].touched;">Nicht genügend Betten</mat-error>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!--TODO use the stepper buttons https://material.angular.io/components/stepper/api#MatStepperNext-->
        <button mat-raised-button [disabled]="isLoading()" [mat-dialog-close]="false">Schließen</button>
        <button mat-raised-button
        [disabled]="isLoading() || ((stayFormGroup.errors || contactFormGroup.errors) && isLastStep)"
        color="primary"
        (click)="onContinueClick()">{{isLastStep ?
            isInEditMode ? 'Speichern' : 'Erstellen' : 'Weiter'}}</button>
        <button *ngIf="isInEditMode && isLastStep" mat-raised-button color="warn" (click)="onDeleteClick()"
            [disabled]="isLoading()">Löschen</button>
    </mat-dialog-actions>
</div>