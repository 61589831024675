import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { DateOnly } from './custom-date-adapter';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  private readonly baseUrl: string = environment.apiBaseUrl;
  private subscriptions: Subscription[] = [];

  private bookings: WritableSignal<Booking[]> = signal<Booking[]>([]);
  private loadingTracker: WritableSignal<number> = signal<number>(0);
  private isLoading: Signal<boolean> = computed<boolean>(() => this.loadingTracker() != 0);
  private rooms: WritableSignal<Room[]> = signal<Room[]>([]);
  private booking: WritableSignal<Booking | null> = signal<Booking | null>(null);
  private roomBookings: WritableSignal<RoomBooking[]> = signal<RoomBooking[]>([]);

  constructor(private http: HttpClient) { }

  public getBookings(): Signal<Booking[]> {
    return this.bookings;
  }

  public getBooking(): Signal<Booking | null> {
    return this.booking;
  }

  public getIsLoading(): Signal<boolean> {
    return this.isLoading;
  }

  public getRooms(): Signal<Room[]> {
    return this.rooms;
  }

  public getRoomBookings(): Signal<RoomBooking[]> {
    return this.roomBookings;
  }

  public fetchAllBookings(): void {
    this.incrementLoadingTracker();
    // TODO how to unsubscribe here? (and all the other places with the same pattern)
    this.subscriptions.push(this.http.get<Booking[]>(`${this.baseUrl}/api/Bookings/`).subscribe(bookings => {
      this.bookings.set(bookings.map(this.mapResponseBookingToBooking));
      this.decrementLoadingTracker();
    }));
  }

  public createNewBooking(bookingToCreate: CreateBookingCommand): Observable<Object> {
    const httpResponseObservable = this.http.post(`${this.baseUrl}/api/Bookings/`, bookingToCreate);
    return httpResponseObservable;
  }

  public updateBooking(updatedBooking: UpdateBookingCommand): Observable<Object> {
    const httpResponseObservable = this.http.put(`${this.baseUrl}/api/Bookings/${updatedBooking.bookingToUpdate.id}`, updatedBooking);
    return httpResponseObservable;
  }

  public async deleteBooking(bookingId: string) {
    this.incrementLoadingTracker();
    const httpResponseObservable = this.http.delete(`${this.baseUrl}/api/Bookings/${bookingId}`);
    var response = await firstValueFrom(httpResponseObservable);
    this.decrementLoadingTracker();
    return response;
  }

  public fetchBooking(bookingId: string): void {
    this.incrementLoadingTracker();
    this.subscriptions.push(this.http.get<Booking>(`${this.baseUrl}/api/Bookings/${bookingId}`).subscribe(booking => {
      this.booking.set(this.mapResponseBookingToBooking(booking));
      this.decrementLoadingTracker();
    }));
  }

  public resetBooking(): void {
    this.booking.set(null);
  }

  public fetchAllRooms(): void {
    this.incrementLoadingTracker();
    this.subscriptions.push(this.http.get<Room[]>(`${this.baseUrl}/api/Rooms/`).subscribe(rooms => {
      this.rooms.set(rooms);
      this.decrementLoadingTracker();
    }));
  }

  public async createPdfInvoice(
    bookingId: string,
    billingAddress: Address,
    drinkPrice: number | null,
    numberOfBreakfasts: number | null,
    numberOfChildrenBreakfasts: number | null): Promise<void>{
      this.incrementLoadingTracker();
      const httpResponseObservable = this.http.post(
        `${this.baseUrl}/api/Bills/createPdf`,
        { bookingId,
          billingAddress,
          numberOfBreakfasts,
          drinkPrice, 
          numberOfChildrenBreakfasts },
        { responseType: 'blob' });
      var invoice = await firstValueFrom(httpResponseObservable);
      this.downloadFile(invoice, `rechnung_${bookingId}.pdf`);
      this.decrementLoadingTracker();
  }

  public fetchRoomBookings(fromDate: DateOnly | null = null, toDate: DateOnly | null = null): void {
    this.incrementLoadingTracker();
    let queryParams = new HttpParams();
    // TODO: fix the ugly date formatting (use own date provider?)
    if (fromDate != null) queryParams = queryParams.set('from', fromDate.toISOString());
    if (toDate != null) queryParams = queryParams.set('to', toDate.toISOString());
    this.subscriptions.push(this.http.get<RoomBooking[]>(`${this.baseUrl}/api/Bookings/roomBooking`, { params: queryParams }).subscribe(roomBookings => {
      roomBookings.forEach(roomBooking => {
        roomBooking.fromDate = DateOnly.parse(roomBooking.fromDate as unknown as string);
        roomBooking.toDate = DateOnly.parse(roomBooking.toDate as unknown as string);
      });
      this.roomBookings.set(roomBookings);
      this.decrementLoadingTracker();
    }));
  }

  public resetRoomBookings(): void {
    this.roomBookings.set([]);
  }

  private downloadFile(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  // TODO: understand why this is needed and if can be done better?
  // If not done the dates are actually strings not date objects
  // How to customize json parser behaviour
  private mapResponseBookingToBooking(booking: Booking): Booking {
    booking.endDate = DateOnly.parse(booking.endDate as unknown as string);
    booking.startDate = DateOnly.parse(booking.startDate as unknown as string);
    booking.bookedAt = new Date(booking.bookedAt);
    return booking;
  }

  private incrementLoadingTracker(): void {
    this.loadingTracker.update(value => value + 1);
  }

  private decrementLoadingTracker(): void {
    this.loadingTracker.update(value => value - 1);
  }
}

export interface CreateBookingCommand {
  bookingToCreate: Booking;
  force: boolean;
}

export interface UpdateBookingCommand {
  bookingToUpdate: Booking;
  force: boolean;
}

export interface Booking {
  id: string;
  startDate: DateOnly;
  endDate: DateOnly;
  bookedAt: Date;
  numberOfGuests: Record<GuestType, number>;
  contactPerson: ContactPerson;
  occupiedRooms: Array<Room>;
}

export interface ContactPerson {
  firstName: string,
  lastName: string,
  phone: string | null,
  email: string | null
}

export enum GuestType {
  Adult = 'Adult',
  Youth = 'Youth',
  Child = 'Child'
}

export interface Room {
  roomNumber: string;
  capacity: number;
}

export interface RoomBooking {
  fromDate: DateOnly;
  toDate: DateOnly;
  occupiedRoom: Room;
}

export interface Address
{
    name: string;
    company: string | null;
    street: string;
    postalCode: string;
    city: string;
}