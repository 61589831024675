import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select';

import { BookingsOverviewComponent } from './bookings-overview/bookings-overview.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingsOverviewCalendarComponent } from './bookings-overview-calendar/bookings-overview-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { LoadingSpinnerDirective } from '../common/loading-spinner.directive';
import { CreateBillDialogComponent } from './create-bill-dialog/create-bill-dialog.component';
import { DateOnlyAdapter } from './custom-date-adapter';

@NgModule({
  declarations: [
    BookingsOverviewComponent,
    BookingDetailsComponent,
    BookingsOverviewCalendarComponent,
    CreateBillDialogComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatStepperModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    FullCalendarModule,
    LoadingSpinnerDirective,
  ],
  exports: [
    BookingsOverviewComponent
  ],
  providers: [
    {provide: DateAdapter, useClass: DateOnlyAdapter}
  ]
})
export class BookingsModule { }
