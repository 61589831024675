import { Component, computed, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { Booking, BookingsService } from '../bookings.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { CreateBillDialogComponent } from '../create-bill-dialog/create-bill-dialog.component';

@Component({
    selector: 'app-bookings-overview',
    templateUrl: './bookings-overview.component.html',
    styleUrls: ['./bookings-overview.component.css'],
    standalone: false
})
export class BookingsOverviewComponent implements OnInit {
  public displayedColumns: string[] = ["startDate", "endDate", "contactLastName", "numberOfGuests", "occupiedRooms", "actions"]
  
  constructor(public dialog: MatDialog,
    private bookingsService: BookingsService)
  {
  }

  private bookings: Signal<Booking[]> = signal([]);
  public bookingTableData: Signal<MatTableDataSource<Booking>> = computed(() => {
    const bookings = this.bookings();
    const data =  new MatTableDataSource(bookings);
    data.sort = this.sort;
    return data;
  });
  public isLoading: Signal<boolean> = signal(false);
  
  ngOnInit(): void {
    this.bookings = this.bookingsService.getBookings();
    this.isLoading = this.bookingsService.getIsLoading();
    this.bookingsService.fetchAllBookings();
  }

  @ViewChild(MatSort) sort: MatSort = undefined!;

  async openAddDialog() : Promise<void>
  {
    const dialogRef = this.dialog.open(BookingDetailsComponent, {
      data: null,
    });

    dialogRef.afterClosed().subscribe(this.onDetailsClosed.bind(this));
  }

  async onRowClick(bookingId: string)
  {
    const dialogRef = this.dialog.open(BookingDetailsComponent, {
      data: bookingId,
    });
    dialogRef.afterClosed().subscribe(this.onDetailsClosed.bind(this));
  }

  async onDetailsClosed(reloadNeeded: boolean): Promise<void>
  {
    // TODO: this is not really needed after a state has been implemented
    if(reloadNeeded === true)
    {
      this.bookingsService.fetchAllBookings();
    }
  }

  async createInvoice(bookingId: string): Promise<void>
  {
    this.dialog.open(CreateBillDialogComponent, {
      data: bookingId,
    });
  }

  public getTotalNumberOfGuests(booking: Booking): number{
    return Object.values(booking.numberOfGuests).reduce((a, b) => a + b, 0);
  }

  public getCommaSeparatedRoomNumbers(booking: Booking): string{
    return booking.occupiedRooms.map(room => room.roomNumber).join(', ');
  }
}
