import { Component, computed, effect, Inject, OnInit, signal, Signal, ViewChild, WritableSignal } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Booking, BookingsService, CreateBookingCommand, Room, UpdateBookingCommand } from '../bookings.service';
import { HttpErrorResponse } from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DateOnly } from '../custom-date-adapter';

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
    styleUrls: ['./booking-details.component.css'],
    standalone: false
})
// TODO: atleastone validator for contacts
export class BookingDetailsComponent implements OnInit {

  readonly maximumNumberOfGuests = 34;

  contactFormGroup = this.formBuilder.group({
    firstName: new FormControl<string>('', {validators: [Validators.required]}),
    lastName: new FormControl<string>('', {validators: [Validators.required]}),
    phone: new FormControl<string>(''),
    email: new FormControl<string>('', {validators: [Validators.email]}),
  });

  stayFormGroup = this.formBuilder.group({
    startDate: new FormControl<DateOnly | null>(null, {validators: [Validators.required]}),
    endDate: new FormControl<DateOnly | null>(null, {validators: [Validators.required]}),
    numberOfAdults: new FormControl<number>(0, {validators: [Validators.required, Validators.min(1), Validators.max(this.maximumNumberOfGuests)]}),
    numberOfYouths: new FormControl<number>(0, {validators: [Validators.required]}),
    numberOfChildren: new FormControl<number>(0, {validators: [Validators.required]}),
    occupiedRooms: new FormControl<Room[]>([], {validators: [Validators.required]}),
    force: new FormControl<boolean>(false, {}),
  }, {validators: [this.createCapacityValidator()]});

  @ViewChild('stepper') private myStepper: MatStepper = undefined!;
  isLastStep: boolean = false;
  isInEditMode: boolean = false;
  public rooms: Signal<Room[]> = signal<Room[]>([]);
  public booking: Signal<Booking | null> = signal<Booking | null>(null);
  public isServiceLoading: Signal<boolean> = signal<boolean>(false);
  public isComponentLoading: WritableSignal<boolean> = signal<boolean>(false);
  public isLoading: Signal<boolean> = computed<boolean>(() => this.isServiceLoading() || this.isComponentLoading());

  public constructor(
    public dialogRef: MatDialogRef<BookingDetailsComponent>,
    private formBuilder: FormBuilder,
    private bookingsService: BookingsService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public selectedBookingId: string | null) {
        effect(this.populateFormWithExistingData.bind(this));
      }

  public ngOnInit(): void {
    this.bookingsService.resetBooking();
    this.rooms = this.bookingsService.getRooms();
    this.bookingsService.fetchAllRooms();
    this.booking = this.bookingsService.getBooking();
    this.isServiceLoading = this.bookingsService.getIsLoading();
    if(this.selectedBookingId != null)
    {
      this.isInEditMode = true;
      this.bookingsService.fetchBooking(this.selectedBookingId)
      this.populateFormWithExistingData();
    }
    else{
      this.isInEditMode = false;
    }
  }
  
  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public async onContinueClick(): Promise<void> {
    if(!this.isLastStep)
    {
      this.myStepper.next();
    }
    else if(this.isInEditMode)
    {
      // TODO: unsubscribe
      var booking = this.convertFormData();
      var bookingToUpdate = {
        bookingToUpdate: booking,
        force: this.stayFormGroup.value.force
      } as UpdateBookingCommand;
      this.isComponentLoading.set(true);
      // TODO unsubscribe
      var sub = this.bookingsService.updateBooking(bookingToUpdate)
      .subscribe({
        next: this.closeDialogAndReload.bind(this),
        error: this.onWriteError.bind(this),
        complete: this.closeDialogAndReload.bind(this)})
    }
    else
    {
      var booking = this.convertFormData();
      var bookingToCreate = {
        bookingToCreate: booking,
        force: this.stayFormGroup.value.force
      } as CreateBookingCommand;
            // TODO unsubscribe
      this.isComponentLoading.set(true);
      var subscription = this.bookingsService
        .createNewBooking(bookingToCreate)
        .subscribe({next: this.closeDialogAndReload.bind(this),
          error: this.onWriteError.bind(this),
          complete: this.closeDialogAndReload.bind(this)});
    }
  }

  public onSelectionChange()
  {
    this.isLastStep = this.myStepper.selectedIndex == this.myStepper.steps.length - 1
  }

  public async onDeleteClick()
  {
    const booking = this.booking();
    await this.bookingsService.deleteBooking(booking!.id!)
    this.dialogRef.close(true);
  }

  private convertFormData() : Booking
  {
    const booking = this.booking();
    return {
      contactPerson: this.contactFormGroup.value,
      startDate: this.stayFormGroup.value.startDate,
      endDate: this.stayFormGroup.value.endDate,
      numberOfGuests: {
        Youth: this.stayFormGroup.value.numberOfYouths,
        Adult: this.stayFormGroup.value.numberOfAdults,
        Child: this.stayFormGroup.value.numberOfChildren
      },
      occupiedRooms: this.stayFormGroup.value.occupiedRooms,
      id: booking?.id,
      bookedAt: booking?.bookedAt
    } as Booking
  }

  private populateFormWithExistingData()
  {
    const booking = this.booking();
    if (booking) {
      this.contactFormGroup.setValue({
        firstName: booking.contactPerson.firstName,
        lastName: booking.contactPerson.lastName,
        phone: booking.contactPerson.phone,
        email: booking.contactPerson.email
      });

      this.stayFormGroup.setValue({
        startDate: booking.startDate,
        endDate: booking.endDate,
        numberOfAdults: booking.numberOfGuests.Adult,
        numberOfYouths: booking.numberOfGuests.Youth,
        numberOfChildren: booking.numberOfGuests.Child,
        occupiedRooms: this.rooms().filter(room => booking.occupiedRooms.map(r => r.roomNumber).includes(room.roomNumber)),
        force: false // Set default value for force
      });
    }
  }

  private createCapacityValidator() : ValidatorFn
  {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(control.get('force')?.value)
      {
        return null;
      }
      const numberOfAdults = control.get('numberOfAdults')?.value as number;
      const numberOfYouths = control.get('numberOfYouths')?.value as number;
      const numberOfChildren = control.get('numberOfChildren')?.value as number;
      const totalGuests = numberOfAdults + numberOfYouths + numberOfChildren;

      const rooms = control.get('occupiedRooms')?.value as Room[];
      const totalCapacity = rooms.reduce((acc, room) => acc + room.capacity, 0);
      if(totalGuests > totalCapacity)
      {
        return {capacityExceeded: true}
      }

      return null;
    }
  }

  private closeDialogAndReload(): void
  {
    this.isComponentLoading.set(false);
    this.dialogRef.close(true);
  }

  private onWriteError(errorResponse: HttpErrorResponse): void
  {
    // TODO move this to http interceptor and add typing
    this.isComponentLoading.set(false);
    const errors: any[] = errorResponse.error.errors;
    this.snackBar.open(errors.map(e => e.ErrorMessage).join('\n'), 'Verstanden');
  }

}
